export default function() {
  
  if (document.querySelector('body').classList.contains('single-news')) {
    var anchors = document.querySelectorAll('.ht-anchor');
    anchors.forEach(function (anchor) {
      var childNode = document.createElement('li');
      childNode.innerHTML = '<a href="#' + anchor.getAttribute('name') + '">' + anchor.nextElementSibling.querySelectorAll('h1, h2, h3')[0].innerText + '</a>';
      document.querySelector('.ty-news-header-list').appendChild(childNode);
    });
  }
  
  if (window.innerWidth > 876) {
    if (document.querySelector('body').classList.contains('single-news')) {
      var body = document.querySelector('body').clientHeight;
      var menu = document.querySelector('.ty-header-news-contact').clientHeight + 152;
      var footer = document.querySelector('.ht-footer-socials').clientHeight + document.querySelector('footer').clientHeight;
      
      window.addEventListener('resize', function () {
        body = document.querySelector('body').clientHeight;
        menu = document.querySelector('.ty-header-news-contact').clientHeight;
        footer = document.querySelector('.ht-footer-socials').clientHeight + document.querySelector('footer').clientHeight;
      });

      window.addEventListener('scroll', function () {
        if (window.pageYOffset >= ( body - menu - (footer))) {
          document.querySelector('.ty-header-news-contact').style.bottom = (footer) - (body - (window.pageYOffset + window.innerHeight) - 32) + 'px';
        } else {
          document.querySelector('.ty-header-news-contact').style.bottom = 'auto';
        }
      });
    }
  } else {
    if (document.querySelector('body').classList.contains('single-news')) {
      document.querySelector('.ht-header-news').style.marginBottom = document.querySelector('.ty-header-news-contact').clientHeight + 'px'; 

      window.addEventListener('resize', function () {
        document.querySelector('.ht-header-news').style.marginBottom = document.querySelector('.ty-header-news-contact').clientHeight + 'px'; 
      });
    }
  }
}
