import $ from "jquery";
import "../slick.min";

export default function () {
  var index = 0;
  var arrowIndex = 0;

  document.querySelectorAll(".slider").forEach(function (elem) {
    index++;
    elem.classList.add("slider-index-" + index);
    var $slick = $(elem);

    if (elem.nextElementSibling !== null) {
      elem.nextElementSibling.classList.add("arrow-wrapper-" + index);
    }

    var count = 0;
    document.querySelectorAll(".ht-servicecard").forEach(function () {
      count++;
    });

    var count2 = 0;
    document.querySelectorAll(".ht-marketcard").forEach(function () {
      count2++;
    });

    $slick.slick({
      infinite: false,
      slidesToShow: 3,
      variableWidth: true,
      arrows: true,
      nextArrow: '<i class="slider-buttons button-right"></i>',
      prevArrow: '<i class="slider-buttons button-left"></i>',
      appendArrows: $(".arrow-wrapper-" + index),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $slick.on("setPosition", function () {
      if (window.innerWidth > 1024) {
        document
          .querySelectorAll(".ht-serviceslider")
          .forEach(function (cards) {
            var trackWidth = 312 * count;
            var listMarginLeft =
              (cards.clientWidth -
                cards.querySelector(".ht-wrapper").clientWidth) /
              2;
            cards.querySelector(".slick-track").style.width = trackWidth + "px";
          });

        document.querySelectorAll(".ht-marketslider").forEach(function (cards) {
          var trackWidth = 312 * count2;
          var listMarginLeft =
            (cards.clientWidth -
              cards.querySelector(".ht-wrapper").clientWidth) /
            2;
          cards.querySelector(".slick-track").style.width = trackWidth + "px";
        });
      }
    });
  });

  $(".ht-vacancy-items").slick({
    infinite: false,
    slidesToShow: 3,
    arrows: true,
    dots: true,
    autoplaySpeed: 6000,
    autoplay: true,
    nextArrow: '<i class="slider-buttons button-right"></i>',
    prevArrow: '<i class="slider-buttons button-left"></i>',
    appendArrows: $(".ht-vacancy-arrow-wrapper"),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".ht-cards-items-2").slick({
    infinite: false,
    slidesToShow: 2,
    arrows: false,
    dots: false,
    autoplaySpeed: 6000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  });

  $(".ht-cards-items-3").slick({
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  });

  if (window.innerWidth > 1024) {
    document.querySelectorAll(".ht-serviceslider").forEach(function (cards) {
      var listMarginLeft =
        (cards.clientWidth - cards.querySelector(".ht-wrapper").clientWidth) /
        2;
      var trackMarginLeft =
        (cards.clientWidth - cards.querySelector(".ht-wrapper").clientWidth) /
          2 +
        cards.querySelector(".ht-wrapper").clientWidth * 0.1699347 -
        12;
      cards.querySelector(".ht-servicecard-list").style.marginLeft =
        "-" + listMarginLeft + "px";
      cards.querySelector(".slick-track").style.marginLeft =
        trackMarginLeft + "px";
    });

    document.querySelectorAll(".ht-marketslider").forEach(function (cards) {
      var listMarginLeft =
        (cards.clientWidth - cards.querySelector(".ht-wrapper").clientWidth) /
        2;
      var trackMarginLeft =
        (cards.clientWidth - cards.querySelector(".ht-wrapper").clientWidth) /
          2 +
        cards.querySelector(".ht-wrapper").clientWidth * 0.1699347 -
        12;
      cards.querySelector(".ht-marketcard-list").style.marginLeft =
        "-" + listMarginLeft + "px";
      cards.querySelector(".slick-track").style.marginLeft =
        trackMarginLeft + "px";
    });
  }
}
