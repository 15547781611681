import $ from "jquery";
import polyfills from "./_polyfills";
import sliders from "./parts/_slider";
import mobileNavToggle from "./parts/_mobileNavToggle";
import formTabs from "./parts/_formTabs";
//import cookies from './parts/_cookies';
//import cookieSettings from './parts/_cookieSettings';
import anchorList from "./parts/_anchor-list";

polyfills();
mobileNavToggle();
formTabs();
sliders();
//cookies();
//cookieSettings();
anchorList();

// on load event
window.addEventListener("DOMContentLoaded", function () {
  resizeHeader();

  setMarginBottom();

  if (window.location.hash) {
    var noHash = window.location.hash.replace("#", "");
    document.querySelectorAll(".ht-form-tab-links").forEach(function (links) {
      links.parentElement.classList.remove("current");
      if (links.dataset.tab === noHash) {
        links.parentElement.classList.add("current");
      }
    });

    document.querySelectorAll(".ht-form-tab").forEach(function (tabs) {
      tabs.classList.remove("current");
      if (tabs.dataset.targetTab === noHash) {
        tabs.classList.add("current");
        tabs
          .querySelector("form")
          .setAttribute(
            "action",
            "/contact/#" +
              document.querySelector(".ht-form-tab.current").dataset.targetTab
          );
      }
    });
  }

  // scroll to anchor
  $(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();
    var target = $.attr(this, "href").replace("#", "");
    if (document.querySelector('a[name="' + target + '"]') !== null) {
      $("html, body").animate(
        {
          scrollTop: $('a[name="' + target + '"]').offset().top - 160 + "px",
        },
        1000,
        "swing"
      );
    }
  });

  if (window.innerWidth > 568) {
    formButtonPosition();
  } else {
    document
      .querySelector(".ht-newsletter-form .gform_button")
      .removeAttribute("style");
  }

  if (window.innerWidth <= 1024) {
    addArrows();

    $(".ht-upper-nav .ht-util-nav ul li.menu-item").on("click", function (e) {
      e.preventDefault();
      $(this).find("ul.sub-menu").toggleClass("show");
    });

    $(".ht-upper-nav .ht-util-nav ul li.menu-item ul.sub-menu li a").on(
      "click",
      function (e) {
        e.preventDefault();
        window.location.href = e.target;
      }
    );
  } else {
    removeArrows();
  }

  if (window.innerWidth <= 736) {
    mobileHeaderVisualTitle();
    mobileHeaderVisualButton();
  } else if (window.innerWidth > 736) {
    desktopHeaderVisualTitle();
    // desktopHeaderVisualButton();
  }

  if (window.innerWidth > 1024) {
    // open search when button is clicked.
    document
      .querySelector(".ht-search form")
      .addEventListener("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.classList.add("search-open");
      });

    // close search when clicking outside search window.
    document.querySelector("body").addEventListener("click", function (e) {
      document.querySelector(".ht-search form").classList.remove("search-open");
    });
  }

  // when button is clicked or enter is pressed, submit form
  document.querySelectorAll(".ht-search-field").forEach(function (field) {
    field.addEventListener("keyup", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
  });

  // submit form on click
  document.querySelectorAll(".ht-search-button").forEach(function (button) {
    button.addEventListener("click", function (e) {
      if (document.querySelector(".ht-search-field").value !== "") {
        e.preventDefault();
        document.querySelector("#searchform").submit();
      }
    });
  });

  // read more
  if (document.querySelector(".ht-read-more-btn")) {
    document.querySelectorAll(".ht-read-more-btn").forEach(function (button) {
      button.addEventListener("click", function () {
        button.classList.toggle("open");
        $(this).parent().prev().slideToggle();
      });
    });
  }

  // faq
  if (document.querySelector(".ht-faq")) {
    document.querySelectorAll(".ht-faq-question").forEach(function (button) {
      button.addEventListener("click", function () {
        button.classList.toggle("open");
        $(this).next().slideToggle();
      });
    });
  }

  /*
  if (window.getCookie('EuCookieBanner') === null) {
    var cookieEvent = new Event('cookiebarLoaded');
    var cookiebar = document.createElement('div');
      cookiebar.className = 'ht-cookie';
      cookiebar.innerHTML = '<div class="ht-cookie__bar"><div class="ht-cookie__wrapper"><div class="ht-cookie__text">Deze website maakt gebruik van functionele en statistieken cookies, die nodig zijn om deze site zo goed mogelijk te laten functioneren. Hiernaast kan je aangeven welke andere soorten cookies je wilt accepteren.</div><a href="/cookies" class="ht-cookie__change-cookies link"><span>Aangepast</span></a><a href="" class="button large regular fill red ht-cookie__agree"><span>Accepteer cookies</span></a></div></div>';
      document.body.appendChild(cookiebar);
      window.dispatchEvent(cookieEvent);
  }
  */

  var modal = $(".ht-contact-form-modal");
  var btn = $("#ht-contact-form-icon");
  var span = $(".close");

  btn.on("click", function () {
    modal.css("display", "block");
  });

  span.on("click", function () {
    modal.css("display", "none");
  });

  window.onclick = function (event) {
    if (event.target == modal) {
      modal.css("display", "none");
    }
  };

  var inputField = document.getElementById("input_32_1_3");

  if (inputField) {
    inputField.addEventListener("input", function () {
      // Code to execute when the input field is changed
      document.getElementById("input_32_9").value =
        document.getElementById("entry_id").innerText;
    });
  }

  const footerButton = document.querySelector(".ht-footer-show");
  const footerLinks = document.querySelectorAll(".ht-footer-hide");
  // Check if footer button exists
  if (footerButton) {
    footerButton.addEventListener("click", () => {
      footerButton.classList.toggle("open");
      footerLinks.forEach((link) => {
        link.classList.toggle("open");
      });
    });
  }
});

// window resize event
window.addEventListener("resize", function () {
  resizeHeader();
  setMarginBottom();

  if (window.innerWidth > 568) {
    formButtonPosition();
  } else {
    document
      .querySelector(".ht-newsletter-form .gform_button")
      .removeAttribute("style");
  }

  if (window.innerWidth <= 736) {
    mobileHeaderVisualTitle();
    mobileHeaderVisualButton();
  } else if (window.innerWidth > 736) {
    desktopHeaderVisualTitle();
    // desktopHeaderVisualButton();
  }

  if (window.innerWidth <= 1024) {
    addArrows();
  } else {
    removeArrows();
  }
});

function resizeHeader() {
  if (document.querySelectorAll(".ht-header-visual")) {
    document.querySelectorAll(".ht-header-visual").forEach((el) => {
      if (window.innerWidth <= 1025) {
        el.style.marginTop =
          document.querySelector(".ht-header").offsetHeight +
          document.querySelector(".ht-upper-nav").offsetHeight +
          document.querySelector(".ht-contact-bar > a").offsetHeight +
          "px";
      } else {
        el.style.marginTop =
          document.querySelector(".ht-header").offsetHeight +
          document.querySelector(".ht-upper-nav").offsetHeight +
          "px";
      }
    });
  }
}

function formButtonPosition() {
  if (document.querySelector(".ht-newsletter-form") !== null) {
    document
      .querySelector(".ht-newsletter-form .gform_button")
      .removeAttribute("style");
    var btn = document
      .querySelector(".ht-newsletter-form .gform_button")
      .getBoundingClientRect();
    var input = document
      .querySelector(".ht-newsletter-form .ginput_container_email > input")
      .getBoundingClientRect();
    var x = input.right - btn.left + 16;
    var y = input.top - btn.top;
    //document.querySelector('.ht-newsletter-form .gform_button').style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  }
}

function addArrows() {
  document.querySelectorAll(".menu-item-has-children").forEach(function (el) {
    if (el.querySelector("span.toggle-sub-menu") === null) {
      var arrow = document.createElement("span");
      arrow.innerHTML = '<i class="fas fa-chevron-down"></i>';
      arrow.classList.add("toggle-sub-menu");
      el.querySelector("a").appendChild(arrow);
    }
  });

  document.querySelectorAll(".toggle-sub-menu").forEach(function (elem) {
    elem.addEventListener("click", function (e) {
      e.preventDefault();
      elem.parentElement.classList.toggle("open");
      elem.parentElement.nextElementSibling.classList.toggle("open");
    });
  });
}

function removeArrows() {
  document.querySelectorAll(".menu-item-has-children a").forEach(function (el) {
    if (el.querySelector("span.toggle-sub-menu") !== null) {
      el.querySelector("span.toggle-sub-menu").remove();
    }
  });
}

function mobileHeaderVisualTitle() {
  if (
    document.querySelector(
      ".ht-header-visual--image .ht-header-visual--column-left"
    )
  ) {
    var headerH1 = document.querySelector(
      ".ht-header-visual--image .ht-header-visual--column-left h1"
    );
    headerH1.style.marginTop = "-" + (headerH1.clientHeight + 16) + "px";
  }
}

function desktopHeaderVisualTitle() {
  if (
    document.querySelector(
      ".ht-header-visual--image .ht-header-visual--column-left"
    )
  ) {
    var headerH1 = document.querySelector(
      ".ht-header-visual--image .ht-header-visual--column-left h1"
    );
    headerH1.style.marginTop = "0";
  }
}

function mobileHeaderVisualButton() {
  if (
    document.querySelector(
      ".ht-header-visual .ht-header-visual--column-right > .button"
    )
  ) {
    $(".ht-header-visual--column-right > .button").insertBefore(
      ".ht-header-visual--column-left > .link"
    );
  }
}

$(window).on("scroll", function () {
  resizeHeader();

  if (document.querySelector(".scrollbar") !== null) {
    scrollProgress();
  }

  if ($(document).scrollTop() > 1) {
    $(".ht-header").addClass("shadowed");
    $(".ht-upper-nav").addClass("scrolled");
  } else {
    $(".ht-header").removeClass("shadowed");
    $(".ht-upper-nav").removeClass("scrolled");
  }
});

function scrollProgress() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.querySelector(".scrollprogress").style.width = scrolled + "%";
}

function setMarginBottom() {
  var formContent = document.querySelector(".ht-form-content");
  var formBlock = document.querySelector(".ht-contact-block.form");

  if (formContent && formBlock) {
    if (window.innerWidth <= 1024) {
      var height = formContent.offsetHeight;
    } else {
      var height = 24;
    }

    formBlock.style.marginBottom = height + "px";
  }
}
