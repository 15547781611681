import fetch from 'fetch-ie8';
import Promise from 'es6-promise';
export default function () {
  if (!window.polyfilled) {
    window.polyfilled = true;

    if (!NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    if (!window.fetch) {
      window.fetch = fetch;
    }

    if (!window.Promise) {
      window.Promise = Promise;
    }

    if (!String.prototype.includes) {
      String.prototype.includes = function (arg) { return this.indexOf(arg) !== -1; };
    }
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (arg) { return this.indexOf(arg) === 0; };
    }
    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function (arg) { return this.indexOf(arg) === this.length - arg.length; };
    }

    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function () {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    Element.prototype._addEventListener = Element.prototype.addEventListener;
    Element.prototype.addEventListener = function (a, b, c = false) {
      this._addEventListener(a, b, c);
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (!this.eventListenerList[a])
        this.eventListenerList[a] = [];
      //this.removeEventListener(a,b,c); // TODO - handle duplicates..
      this.eventListenerList[a].push({
        listener: b,
        useCapture: c
      });
    };

    Element.prototype.getEventListeners = function (a) {
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (a == undefined)
        return this.eventListenerList;
      return this.eventListenerList[a];
    };
    Element.prototype.clearEventListeners = function (a) {
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (a == undefined) {
        for (var x in (this.getEventListeners())) this.clearEventListeners(x);
        return;
      }
      var el = this.getEventListeners(a);
      if (el == undefined)
        return;
      for (var i = el.length - 1; i >= 0; --i) {
        var ev = el[i];
        if (ev.listener.toString().indexOf('gtm.click') < 0) {
          this.removeEventListener(a, ev.listener, ev.useCapture);
        }
      }
    };

    Element.prototype._removeEventListener = Element.prototype.removeEventListener;
    Element.prototype.removeEventListener = function (a, b, c = false) {
      this._removeEventListener(a, b, c);
      if (!this.eventListenerList) {
        this.eventListenerList = {};
      }
      if (!this.eventListenerList[a]) {
        this.eventListenerList[a] = [];
      }
      // Find the event in the list
      for (var i = 0; i < this.eventListenerList[a].length; i++) {
        if (this.eventListenerList[a][i].listener == b && this.eventListenerList[a][i].useCapture == c) { // Hmm..
          this.eventListenerList[a].splice(i, 1);
          break;
        }
      }
      if (this.eventListenerList[a].length == 0)
        delete this.eventListenerList[a];
    };
    window._addEventListener = window.addEventListener;
    window.addEventListener = function (a, b, c) {
      if (c == undefined)
        c = false;
      window._addEventListener(a, b, c);
      if (!window.eventListenerList)
        window.eventListenerList = {};
      if (!window.eventListenerList[a])
        window.eventListenerList[a] = [];
      //window.removeEventListener(a,b,c); // TODO - handle duplicates..
      window.eventListenerList[a].push({
        listener: b,
        useCapture: c
      });
    };

    window.getEventListeners = function (a) {
      if (!window.eventListenerList)
        window.eventListenerList = {};
      if (a == undefined)
        return window.eventListenerList;
      return window.eventListenerList[a];
    };
    window.clearEventListeners = function (a) {
      if (!window.eventListenerList)
        window.eventListenerList = {};
      if (a == undefined) {
        for (var x in (window.getEventListeners())) window.clearEventListeners(x);
        return;
      }
      var el = window.getEventListeners(a);
      if (el == undefined)
        return;
      for (var i = el.length - 1; i >= 0; --i) {
        var ev = el[i];
        if (ev.listener.toString().indexOf('gtm.click') < 0) {
          window.removeEventListener(a, ev.listener, ev.useCapture);
        }
      }
    };

    window._removeEventListener = window.removeEventListener;
    window.removeEventListener = function (a, b, c) {
      if (c == undefined) {
        c = false;
      }
      window._removeEventListener(a, b, c);
      if (!window.eventListenerList) {
        window.eventListenerList = {};
      }
      if (!window.eventListenerList[a]) {
        window.eventListenerList[a] = [];
      }
      // Find the event in the list
      for (var i = 0; i < window.eventListenerList[a].length; i++) {
        if (window.eventListenerList[a][i].listener == b && window.eventListenerList[a][i].useCapture == c) { // Hmm..
          window.eventListenerList[a].splice(i, 1);
          break;
        }
      }
      if (window.eventListenerList[a].length == 0)
        delete window.eventListenerList[a];
    };

    //
    HTMLDocument.prototype._addEventListener = HTMLDocument.prototype.addEventListener;
    HTMLDocument.prototype.addEventListener = function (a, b, c) {
      if (c == undefined)
        c = false;
      this._addEventListener(a, b, c);
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (!this.eventListenerList[a])
        this.eventListenerList[a] = [];
      //this.removeEventListener(a,b,c); // TODO - handle duplicates..
      this.eventListenerList[a].push({
        listener: b,
        useCapture: c
      });
    };

    HTMLDocument.prototype.getEventListeners = function (a) {
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (a == undefined)
        return this.eventListenerList;
      return this.eventListenerList[a];
    };
    HTMLDocument.prototype.clearEventListeners = function (a) {
      if (!this.eventListenerList)
        this.eventListenerList = {};
      if (a == undefined) {
        for (var x in (this.getEventListeners())) this.clearEventListeners(x);
        return;
      }
      var el = this.getEventListeners(a);
      if (el == undefined)
        return;
      for (var i = el.length - 1; i >= 0; --i) {
        var ev = el[i];
        if (ev.listener.toString().indexOf('gtm.click') < 0) {
          this.removeEventListener(a, ev.listener, ev.useCapture);
        }
      }
    };

    HTMLDocument.prototype._removeEventListener = HTMLDocument.prototype.removeEventListener;
    HTMLDocument.prototype.removeEventListener = function (a, b, c) {
      if (c == undefined) {
        c = false;
      }
      this._removeEventListener(a, b, c);
      if (!this.eventListenerList) {
        this.eventListenerList = {};
      }
      if (!this.eventListenerList[a]) {
        this.eventListenerList[a] = [];
      }
      // Find the event in the list
      for (var i = 0; i < this.eventListenerList[a].length; i++) {
        if (this.eventListenerList[a][i].listener == b && this.eventListenerList[a][i].useCapture == c) { // Hmm..
          this.eventListenerList[a].splice(i, 1);
          break;
        }
      }
      if (this.eventListenerList[a].length == 0)
        delete this.eventListenerList[a];
    };

    var oldRequestAnimationFrame = window.requestAnimationFrame;
    var oldCancelAnimationFrame = window.cancelAnimationFrame;
    var oldSetTimeout = window.setTimeout;
    var oldClearTimeout = window.clearTimeout;
    var oldSetInterval = window.setInterval;
    var oldClearInterval = window.clearInterval;
    var requests = [];
    var timeouts = [];
    var intervals = [];
    window.oldTimeout = {
      set: function() { return oldSetTimeout.apply(null, arguments) },
      clear: function() { return oldClearTimeout.apply(null, arguments) }
    }
    window.requestAnimationFrame = function (func) {
      var animframe = oldRequestAnimationFrame(func);
      requests.push(animframe);
      return animframe;
    };

    window.cancelAnimationFrame = function (animframe) {
      oldCancelAnimationFrame(animframe);
      requests.splice(requests.indexOf(animframe), 1);
    };

    window.clearAnimationFrames = function () {
      requests.forEach(function (request) {
        window.cancelAnimationFrame(request);
      });
    };


    window.setTimeout = function (func, dur) {
      var animframe = oldSetTimeout(func, dur);
      timeouts.push(animframe);
      return animframe;
    };

    window.clearTimeout = function (animframe) {
      oldClearTimeout(animframe);
      timeouts.splice(timeouts.indexOf(animframe), 1);
    };

    window.clearTimeouts = function () {
      timeouts.forEach(function (timeout) {
        window.clearTimeout(timeout);
      });
    };


    window.setInterval = function (func, dur) {
      var animframe = oldSetInterval(func, dur);
      intervals.push(animframe);
      return animframe;
    };

    window.clearInterval = function (animframe) {
      oldClearInterval(animframe);
      intervals.splice(intervals.indexOf(animframe), 1);
    };

    window.clearIntervals = function () {
      intervals.forEach(function (interval) {
        window.clearInterval(interval);
      });
    };

    window.clearTimingFunctions = function () {
      window.clearIntervals();
      window.clearTimeouts();
      window.clearAnimationFrames();
    };

    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }
    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }
  }
}
