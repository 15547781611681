export default function() {
  document.querySelectorAll('.ht-form-tabs a').forEach(function (tabLink) {
    tabLink.addEventListener('click', function () {
      var tab = tabLink.dataset.tab;
      if(!tabLink.parentElement.classList.contains('current')) {
        var former = tabLink.parentElement.parentElement.querySelector('.current');
        former.classList.remove('current');
        tabLink.parentElement.classList.add('current');
        document.querySelectorAll('.ht-form-tab').forEach(function (tab) {
          tab.classList.remove('current');
        });
        document.querySelector('.ht-form-tab[data-target-tab="' + tab + '"]').classList.add('current');
        document.querySelector('.ht-form-tab.current form').setAttribute('action', '/contact/#' + document.querySelector('.ht-form-tab.current').dataset.targetTab);
      }
    });
  });
}
